@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-thin {
    font-variation-settings: "wght" 100;
  }
  .font-extralight {
    font-variation-settings: "wght" 200;
  }
  .font-light {
    font-variation-settings: "wght" 300;
  }
  .font-normal {
    font-variation-settings: "wght" 400;
  }
  .font-medium {
    font-variation-settings: "wght" 500;
  }
  .font-semibold {
    font-variation-settings: "wght" 600;
  }
  .font-bold {
    font-variation-settings: "wght" 700;
  }
  .font-extrabold {
    font-variation-settings: "wght" 800;
  }
  .font-black {
    font-variation-settings: "wght" 900;
  }
}

@font-face {
  font-family: "Satoshi-Variable";
  src: url("./assets/fonts/satoshi/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/satoshi/Satoshi-Variable.woff") format("woff"),
    url("./assets/fonts/satoshi/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

input::placeholder {
  color: #252529;
}


#root,
body,
html {
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

.social-dropdown-scrollbar
{
  scrollbar-width: 5px;
  scrollbar-color: #FBBC05;
}

.social-dropdown-scrollbar::-webkit-scrollbar
{
  width: 3px;
}
.social-dropdown-scrollbar::-webkit-scrollbar-track {
  background-color: #000000;
  border-radius: 4px;
 
}
.social-dropdown-scrollbar::-webkit-scrollbar-thumb {
  background-color: #FBBC05;
  border-radius: 4px;
}


.phone-input-style
{
  cursor: default;
}
.react-tel-input  {
  cursor: default;
  font-family: 'Satoshi-Variable';
}

input::-webkit-textfield-decoration-container
input::-webkit-credentials-auto-fill-button
input::-webkit-contacts-auto-fill-button{
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.pca.pcatext { 
  font-family: 'Satoshi-Variable' !important;          
}

.react-tel-input :disabled {
  cursor: default;
  
}


.silver-gradient
{
  background: linear-gradient(0deg, #CDC9C2, #CDC9C2), linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-blend-mode: color, overlay, normal;
}
.gold-gradient
{
  background: linear-gradient(0deg, #C1A875, #C1A875),linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-blend-mode: color, overlay, normal;
}
.collapsed {
  transform: rotate(-90deg);
}
.shadow-custom {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.shadow-text-custom {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.rounded-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.shadow-inset {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-div
{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.custom-div-location
{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.absolute-offset {
  width: calc(100% + 3.2px);
}

.select-open {
  border-radius: 3px 3px 0 0;
  border-top: 2px solid #252529;
  border-right: 2px solid #252529;
  border-left: 2px solid #252529;
  margin-left: 4px;
}
.select-closed {
  border-radius: 3px;
  border: 2px solid #252529;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 4px;
}


@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animated-slide-left {
  animation: slideRightToLeft 0.1s ease-out;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 8px;
}

#payment-form {
  width: 60%;
  height: fit-content;
  border-radius: 4px;
  padding: 20px;
  margin: 0px 20px;
  box-shadow: 0 2px 6px rgb(0 0 0 0.14),
    0 2px 6px rgb(0 0 0 0.14);
}




